<template>
  <!-- DELETE ALERT  -->
  <div v-if="showDelete"
       class="alert alert-success d-flex align-items-center c-Alert"
       role="alert">
    <div>
      Brand {{ selectedBrand }} has been deleted from results!
    </div>
  </div>

  <div :style="{ 'opacity':opacity  }" class="c-No-Break">
    <div class="row justify-content-center">
      <div class="col-md-auto p-4 c-ResultImage" align="center">
        <div>
          <div class="row c-PositionRelative">
            <img class="c-Height-100 c-Width-100 c-Padding-0 c-CursorPointer"
                 v-on:click="clickedResult(data)"
                 v-bind:src="getFileURL(data.file_identifier)"/>
            <div
                v-on:click="deleteBrand(data)"
                class="c-ResultCloseButton c-CursorPointer">
              <img v-if="!deleted" class="c-ResultCloseButtonIcon"
                   src="../../assets/trash_icon.svg">
              <img v-else class="c-ResultCloseButtonIcon"
                   src="../../assets/trash_icon_filled.svg">
            </div>
          </div>
          <div class="row c-BottomCard">
            <div
                class="col-6 d-flex align-items-center justify-content-center"
                align="center">
              <a class="c-MdspText c-No-Underline c-FontSize-12 c-CursorPointer"
                 v-on:click="saveBrand"
                 data-toggle="modal"
                 data-target="#shareModal">

                <img v-if="favorited" class="c-InlineIconBig c-MarginRight-10"
                     src="../../assets/star_icon_filled.svg">
                <img v-else class="c-InlineIconBig c-MarginRight-10"
                     src="../../assets/star_icon.svg">
                favourites</a>

            </div>
            <!--      SHARING MODAL CONTROL      -->
            <div
                class="col-6 d-flex align-items-center justify-content-center"
                align="center">
              <a class="c-MdspText c-No-Underline c-FontSize-12 c-CursorPointer"
                 v-on:click="$emit('open-share-dialog', data)"
                 data-toggle="modal"
                 data-target="#shareModal">
                <img class="c-InlineIconBig c-MarginRight-10"
                     src="../../assets/share-blue.svg">
                share</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Configuration} from "../../router";
import Cookies from "./Cookies";

export default {
  name: "ResultCard.vue",

  props: {
    'data': Object,
    'deletionView': {
      type: Boolean,
      default: false,
    },
    'detailView': {
      type: Boolean,
      default: false,
    }
  },

  components: {},

  data: function () {
    return {
      // delete brand alert trigger var
      showDelete: false,
      // currently selected brand, used to display a name in alert (save, delete)
      selectedBrand: '',
      // used for visually deleting results
      opacity: 100,
      // using to tell whether a brand is in favorites or not
      favorited: false,
      // used to tell whether a brand is removed from results or not
      deleted: false,
    }
  },

  mounted() {
    // checking if a brand is favorited or deleted upon rendering
    this.favorited = this.isFavorite();
    this.deleted = this.isDeleted();
  },

  methods: {
    // Function used to create S3 link for inspiration and result URL's
    getFileURL: function (identifier) {
      let s3_bucket = process.env.VUE_APP_S3_BUCKET;
      let aws_region = process.env.VUE_APP_AWS_REGION;
      let image_prefix = process.env.VUE_APP_IMAGE_PREFIX;
      if (!this.$props.detailView) {
        return "https://" + s3_bucket + ".s3." + aws_region + ".amazonaws.com/" + image_prefix + "_" + identifier;
      } else {
        return "https://" + s3_bucket + ".s3." + aws_region + ".amazonaws.com/" + "wttt_" + identifier;
      }
    },
    // Track if user clicks 'visit website'
    clickedResult: function (result) {
      axios.post(Configuration.BRAND_EVENT_TRACK_URL,
          {
            "brand_id": result.id,
            "action": "visit"
          });
      window.open(result.url, '_blank').focus();
    },

    saveBrand: function(){
      // Getting favourite brands via cookies
      let fav_arr = Cookies.methods.getFavoritedBrands();
      let new_val = [];

      // if a brand is already in favorites array, delete from favorites
      if (this.isFavorite()) {
        new_val = fav_arr.filter(item => item != this.$props.data.id);
        this.favorited = false;

        // if a brand has yet to be added to favorites array, simply push it
      } else {
        fav_arr.push(this.$props.data.id);
        new_val = fav_arr;
        this.favorited = true;
      }
      let new_selection = new_val.join('-');
      let selection_name = 'MDSP_SAVED_BRANDS';

      // return cookie with new values
      document.cookie = selection_name + '=' + new_selection;
    },

    deleteBrand: function(result){
      let del_arr = Cookies.methods.getBannedBrands();
      let new_val = [];

      if (this.isDeleted()) {
        new_val = del_arr.filter(item => item !== this.$props.data.id);
        this.deleted = false;

        // if a brand has yet to be added to deleted array, simply push it
      } else {
        del_arr.push(this.$props.data.id);
        new_val = del_arr;
        this.deleted = true;
        // if ResultCard is not displayed in DeletionView, hide element from sight
        this.deleteResultBrandAlert(result);
        if (!this.$props.deletionView) {
          this.opacity = 0;
        }
      }
      let new_selection = new_val.join('-');
      let selection_name = 'MDSP_DELETED_BRANDS';

      // return cookie with new values
      document.cookie = selection_name + '=' + new_selection;
    },


    isFavorite: function () {
      let fav_arr = Cookies.methods.getFavoritedBrands();
      return fav_arr.includes(this.$props.data.id);
    },

    isDeleted: function () {
      let deleted_arr = Cookies.methods.getBannedBrands();
      return deleted_arr.includes(this.$props.data.id);
    },

    // Deletion alert
    deleteResultBrandAlert: function (result) {
      this.selectedBrand = result.word;
      this.showDelete = true;
      setTimeout(() => {
        this.showDelete = false
      }, 3000);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/components/style.scss";
</style>