<template>
  <!-- HEADER !-->
  <header-item></header-item>
  <div class="container-fluid c-FullScreen">
    <!-- TEXT BELOW HEADER !-->
    <div class="row">
      <div class="col-6-justify-content-md-center">
        <h3 class="mt-4 c-FontWeightBold  ">
          My Favourite Brand Discoveries
        </h3>
      </div>
    </div>

    <div class="row justify-content-center" v-if="!selectionReady">
      <div class="col-auto">
        <p>
          Your favourite brands will appear here. <br>
          Click on the star to mark your favourites.
        </p>
      </div>
    </div>

    <div class="row">
        <div class="col-12">
          <div v-masonry item-selector=".selection_result" id='results'>
            <div v-masonry-tile class="selection_result" v-for="result in brandData"
                 :key="result.id">
              <result-card
                  v-on:open-share-dialog="$refs.share_modal.openShareDialog($event)"
                  :data="result">
              </result-card>
            </div>
          </div>
        </div>
      </div>

    <!-- FOOTER !-->
    <footer-item></footer-item>

    <!-- BOOTSTRAP SOCIAL NETWORK SHARE MODAL !-->
    <share-modal ref="share_modal"></share-modal>
  </div>
</template>

<script>
import Header from './shared/Header.vue'
import Footer from './shared/Footer.vue'
import ShareModal from "./shared/ShareModal";
import ResultCard from "./shared/ResultCard";
import Cookies from "./shared/Cookies";
import axios from "axios";
import {Configuration} from "@/router";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

export default {
  name: "SelectionView",

  components: {
    'header-item': Header,
    'footer-item': Footer,
    'share-modal': ShareModal,
    'result-card': ResultCard,
  },

  data: function () {
    return {
      brandData: '',
      selectionReady: false,
    }
  },

  computed: {
  },
  created() {
    this.getSelectionData();
  },


  methods: {

    // Get brands from favorite selection saved in cookies
    getSelectionData: function () {

      // Reading cookie and de-serializing it into id array
      let save_list = Cookies.methods.getCookie('MDSP_SAVED_BRANDS');
      let save_ids = Cookies.methods.serializeCookies(save_list);

      // Getting actual brand data via API
      axios.post(Configuration.SELECTION_VIEW_URL,
          {"brand_ids": save_ids}, {}).then(response => {
        this.brandData = response.data;
      // If there are favorites, hide instruction on how to use favourites functionality
        if (this.brandData.length > 0){
          this.selectionReady = true;
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "style";
</style>