import {createStore} from 'vuex'
import axios from "axios";
import {Configuration} from "@/router";

export default createStore({
    state: {
        items: [],
        liked: new Set(),
        disliked: new Set(),
        search_string: new String(),
        results: [],
        resultsProcessing: false,
    },
    strict: true,
    mutations: {
        ADD_INSPIRATION_ITEMS(state, newItems) {
            state.items = state.items.concat(newItems);
            newItems.forEach(newItem => {
                state.disliked.add(newItem.id);
            })
        },
        LIKED_INSPIRATION(state, likedId) {
            if (state.liked.has(likedId)) {
                state.liked.delete(likedId);
                state.disliked.add(likedId);
            } else {
                state.liked.add(likedId);
                state.disliked.delete(likedId);
            }
        },
        PROCESS_RESULTS(state, results) {
            state.results = results;
        },
        CLEAR_RESULTS(state) {
            state.results.length = 0;
        },
        CLEAR_INSPIRATIONS(state) {
            state.items.length = 0;
            state.liked.clear();
            state.disliked.clear();
            state.results.length = 0;
        },
        CLEAR_LIKES_DISLIKES(state) {
            state.liked.clear();
            state.disliked.clear();
        },
        UPDATE_SEARCH_STRING(state, string) {
            state.search_string = string;
        },
        SET_PROCESSING_FLAG(state, bool) {
            state.resultsProcessing = bool;
        },
    },
    actions: {
        fetchInspirations(context) {
            axios.post(Configuration.INSPIRATION_URL,
                {
                    'liked': Array.from(context.state.liked),
                    'disliked': Array.from(context.state.disliked)
                })
                .then(response => {
                    context.commit('ADD_INSPIRATION_ITEMS', response.data);
                });
        },
        likedInspiration(context, id) {
            context.commit('LIKED_INSPIRATION', id)
        },
        textSearch(context, removed_ids) {
            context.commit('SET_PROCESSING_FLAG', true);
            axios.post(Configuration.TEXT_SEARCH_URL,
                {'string': context.state.search_string,
                    'removed_ids': removed_ids})
                .then(response => {
                    context.commit('PROCESS_RESULTS', response.data);
                    context.commit('SET_PROCESSING_FLAG', false);
                });
        },
        inspirationSearch(context, removed_ids) {
            console.log(removed_ids);
            context.commit('SET_PROCESSING_FLAG', true);
            axios.post(Configuration.INSPIRATION_SEARCH_URL,
                {
                    'liked': Array.from(context.state.liked),
                    'disliked': Array.from(context.state.disliked),
                    'removed_ids': removed_ids})
                .then(response => {
                    context.commit('PROCESS_RESULTS', response.data);
                    context.commit('SET_PROCESSING_FLAG', false);
                });
        },
        inspirationTrack(context) {
            axios.post(Configuration.INSPIRATION_TRACK_URL,
                {
                    'liked': Array.from(context.state.liked),
                    'disliked': Array.from(context.state.disliked)
                })
        },
        updateSearchString(context, string) {
            context.commit('UPDATE_SEARCH_STRING', string);
        },
        getReadyFlag() {
            return this.state.resultsReady;
        },
        setProcessingFlag(context, bool) {
            context.commit('SET_PROCESSING_FLAG', bool);
        },
        clearResults(context) {
            context.commit('CLEAR_RESULTS');
        },
        clearInspirations(context) {
            context.commit('CLEAR_INSPIRATIONS');
        },
        clearLikesDislikes(context) {
          context.commit('CLEAR_LIKES_DISLIKES');
        },
    },
    modules: {}
})