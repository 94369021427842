<script>

export default {
  name: "Cookies.vue",

  methods: {
    // Get cookie string by cookie name
    getCookie: function (cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    // Check if user has completed onboarding before
    checkOnboardingCookie: function () {
      let cookie = this.getCookie(process.env.VUE_APP_ONBOARDING_COOKIE);
      return cookie === "true";
    },

    // Take user to the main page if he has completed onboarding before
    validateOnboardingCookie: function () {
      return this.checkOnboardingCookie() === true;
    },

    // Splits cookie string separated by "-" into an array
    serializeCookies: function(cookie_string) {
      let split_arr = cookie_string.split('-');
      return split_arr.map(Number);
    },

    // Get banned brands by MDSP_DELETED_BRANDS cookie
    getBannedBrands: function (){
      // Filtering out banned brands
      let ban_list_cookie = this.getCookie('MDSP_DELETED_BRANDS');
      return this.serializeCookies(ban_list_cookie);
    },

    // Get favorite brands by MDSP_SAVED_BRANDS cookie
    getFavoritedBrands: function(){
      let fav_list_cookie = this.getCookie('MDSP_SAVED_BRANDS');
      return this.serializeCookies(fav_list_cookie);
    },
  }

}
</script>

<style scoped>

</style>