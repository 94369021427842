<template>
    <header-item></header-item>
    <div class="container-fluid">
    <div class="c-AppBody">
      <div class="row justify-content-center">
        <div class="col-md-3 mt-3">
          <span>
            <a @click="$router.go(-1)" class="c-No-Underline c-FontSize-12 c-CursorPointer">
              <img class="c-InlineIcon c-FontSize-12" src="../assets/arrow-left-blue.svg"/>
              GO BACK</a>
          </span>
        </div>
        <div class="col-md-3">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 col-xl-4 text-center">
          <img src="../assets/assoc-wheel.svg" class="c-Width-100">
        </div>
      </div>
    <div class="row justify-content-center mt-3" align="center">
      <h3>
        About Us
      </h3>
      <p>
        We help you discover brands that match your mood. Our mood search captures through implicit associations what you’re unable to put into words.<br>
        Most advertising platforms promote brands that have the financial means to outcompete underdog brands through explicit keyword auctions.<br>
        “Talking the talk” is one thing. At Mindspeller, we believe AI should be human (not machine) -centric, helping to promote brands that “walk the talk”.<br>
        Help us build the world’s most exciting new online shopping platform by <router-link to="/recommend-brand">recommending</router-link> a new and original brand (url) to our AI.<br>
      </p>
    </div>
    </div>
    <footer-item></footer-item>
  </div>
</template>
<script>
// import router from "@/router";
import Header from './shared/Header.vue'
import Footer from './shared/Footer.vue'

export default {
  name: "AboutUs.vue",

  components:{
    'header-item':Header,
    'footer-item':Footer,
  },

  mounted() {
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import "style";
</style>