<template>
  <header-item></header-item>
  <div class="container-fluid">
    <div class="c-AppBody">
      <div class="row justify-content-center">
        <div class="col-6 mt-3">
          <span>
            <a @click="$router.go(-1)"
               class="c-No-Underline c-FontSize-12 c-CursorPointer">
              <img class="c-InlineIcon" src="../assets/arrow-left-blue.svg"/>
               GO BACK</a>
          </span>
        </div>
        <div class="col-6">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto card card-body c-RecommendBrandCard">
          <div class="row justify-content-center">
            <div class="col-md-auto">
              <h3 class="c-FontSize-22">Recommend our AI the coolest 'underdog' brand you know.</h3>
              <p>Our AI especially likes brands in new and original product categories.</p>
            </div>
          </div>
          <div class="row justify-content-center c-MarginTop-40">
            <div class="col-md-auto">
              <form action=".">
                <div class="row">
                  <label class="c-FontWeightBold  c-FontSize-10 c-MarginLeft-20"
                         for="brandWebsite">brand website*</label>
                </div>
                <div class="row c-RecommendBrandInput">
                  <input
                        v-on:blur="isURL(websiteUrl)"
                          v-model="websiteUrl"
                          class="c-SearchForm c-No-Outlilne" type="text"
                         id="brandWebsite" name="brandWebsite"
                         placeholder="url...">
                </div>
              </form>
              <div class="row p-2">
                <span>
                  <span v-if="!websiteValid">
                    Given website URL is not valid.
                  </span>
                  <span class="c-MdspText" v-if="message">
                    Thank you for recommending this brand.
                  </span>
                  <span class="c-MdspText" v-if="!canSubmit">
                    Sorry, please wait {{ submission_time }} before next submission.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <input class="c-MdspButtonFull c-Width-25 c-CursorPointer" type="button"
                 v-on:click="clearInput" value="Add brand"/>
<!--                 @click="recaptcha" value="Add brand"/>-->
        </div>
      </div>
    </div>
    <footer-item></footer-item>
  </div>
</template>
<script>
// import router from "@/router";
import Header from './shared/Header.vue'
import Footer from './shared/Footer.vue'
import {Configuration} from "../router";
import axios from "axios";

export default {
  name: "RecommendBrand.vue",

   data() {
      return {
        websiteUrl: '',
        userMail: '',
        mailValid: true,
        websiteValid: true,
        message: false,
        canSubmit: true,
      }
  },

  methods: {
    // Trigger recaptcha verification
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      console.log(token);
      // Do stuff with the received token.
    },
    isEmail(email) {
      let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      this.mailValid = regex.test(email);
    },
    isURL(url) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      this.websiteValid = !!pattern.test(url);
    },
    clearInput(){
      this.isURL(this.websiteUrl)
      if (this.websiteValid === true) {
        let url = this.websiteUrl;
        console.log(url)
        this.message = true;
        setTimeout(() => { this.message = false }, 3000);
        let brand = String(this.websiteUrl);
        this.websiteUrl = '';
        axios.post(Configuration.BRAND_SUBMISSION_URL,
          {"brand_url": brand})
      }
    },
  },

  components: {
    'header-item': Header,
    'footer-item': Footer,
  },

  mounted() {
  },
}
</script>

<style lang="scss" scoped>
@import "style";
</style>