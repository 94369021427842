<template>
  <!-- HEADER !-->
  <header-item></header-item>
  <div class="c-FullScreen-NoFooter c-OnboardingCenter justify-content-center">
  <div class="container-fluid">
    <!-- ONBOARDING WELCOME IMAGE !-->
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-xl-4 text-center">
        <img src="../assets/images/mdsp_head.png"/>
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-12 mt-4 text-center">
        <h3 class="c-FontSize-22 c-OnboardingHeader">
          Discover ethical brands
        </h3>
        <p class="c-FontSize-14 c-OnboardingDescription">
          that share your passion
        </p>
      </div>
    </div>

    <!-- START BUTTON (NOT USED IN PRACTICE) !-->
    <div class="row">
      <div class="col col-sm-12" align="center">
        <button class="c-MdspLikeButton c-MdspBlue circle-button"
                v-on:click="takeToMain()">
          <img src="../assets/arrow-down.svg">
        </button>
      </div>
    </div>

    <!-- COOKIE DISCLAIMER MODAL  -->
  <div v-if="!cookiesValidated"
      class="modal d-flex align-items-center c-translucentGrayBackground" id="onboardingModal" tabindex="-1" role="dialog"
       aria-labelledby="onboardingModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body" align="center">
          <p><b>
            Our website uses cookies
          </b></p>
          <p>
            Accepting the use of cookies allows us to improve your user experience
            by processing anonymous user statistics. These statistics allow us
            to help improve branded content to match your expectations.
            We respect your privacy and do not auction your private profile data
            to the highest bidder. In fact, we are working hard to release
            a new feature that puts you back in control by helping you monetize
            your private cookie data used for marketing purposes.
          </p>
          <p>
            To try our mind search application, click "Accept Cookies" to continue.
          </p>
          <h4 class="c-Blue c-CursorPointer" v-on:click="closeOnboardingModal">
            Accept Cookies
          </h4>
        </div>
      </div>
    </div>
  </div>

  </div>
  </div>
</template>

<script>
import router from "@/router";
import Header from './shared/Header.vue'
import Cookies from "./shared/Cookies";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

export default {
  name: "Onboarding",

  components: {
    'header-item': Header,
  },

  data() {
    return {
      cookiesValidated: false,
    }
  },

  created() {
    this.cookiesValidated = Cookies.methods.validateOnboardingCookie();
  },

  mounted() {
    $("#onboardingModal").show()
    this.$nextTick(function () {
      window.addEventListener('scroll', this.onScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    // Go to inspiration selector
    takeToMain: function () {
      if (Cookies.methods.checkOnboardingCookie() != true) {
        document.cookie = process.env.VUE_APP_ONBOARDING_COOKIE + "=true"
      }
      router.push('inspiration');
    },

    // Clear inspirations, likes, dislikes and results, then fetch new batch of inspirations
    restartInspirationSearch: function() {
      this.$store.dispatch('clearInspirations');
      this.$store.dispatch('fetchInspirations');
    },

    onScroll: function (e) {
      if (e.target.documentElement.scrollTop + e.target.documentElement.clientHeight >= e.target.documentElement.scrollHeight) {
        this.reachedScrollEnd()
      }
    },
    reachedScrollEnd: function () {
      this.takeToMain();
    },

    closeOnboardingModal: function () {
      $("#onboardingModal").remove();
    },
  }
}
</script>

<style lang="scss" scoped>
@import "style";
</style>