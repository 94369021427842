import {createRouter, createWebHashHistory} from 'vue-router'
import Onboarding from "@/components/Onboarding";
import InspirationSelector from "@/components/InspirationSelector";
import ResultsViewer from "@/components/ResultsViewer";
import AboutUs from "@/components/AboutUs";
import KeywordSearch from "../components/KeywordSearch";
import RecommendBrand from "../components/RecommendBrand";
import DetailView from "../components/DetailView";
import SelectionView from "../components/SelectionView";
import DeletionView from "../components/DeletionView";

const routes = [
    {
        path: '/',
        name: 'Onboarding',
        component: Onboarding
    },
    {
        path: '/inspiration',
        name: 'Inspiration Selector',
        component: InspirationSelector
    },
    {
        path: '/results',
        name: 'Results Viewer',
        component: ResultsViewer
    },
    {
        path: '/about-us',
        name: 'About Us',
        component: AboutUs
    },
    {
        path: '/keyword-search',
        name: 'Keyword Search',
        component: KeywordSearch
    },
    {
        path: '/recommend-brand',
        name: 'Recommend Brand',
        component: RecommendBrand
    },
    {
        path: '/detail-view',
        name: 'Detail Brand View',
        component: DetailView
    },
    {
        path: '/selection-view',
        name: 'Selection View',
        component: SelectionView
    },
    {
        path: '/deletion-view',
        name: 'Deletion View',
        component: DeletionView
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router

export const Configuration = Object.freeze({
    NUMBER_OF_INSPIRATION_PULLDOWNS: 40,
    NUMBER_OF_INSPIRATIONS_PER_PULLDOWN: 20,

    // localhost dev config
    // INSPIRATION_URL: 'http://127.0.0.1:5000/data/getInspiration',
    // INSPIRATION_SEARCH_URL: 'http://127.0.0.1:5000/data/getResults',
    // TEXT_SEARCH_URL: 'http://127.0.0.1:5000/data/getKeywordResults',
    // BRAND_EVENT_TRACK_URL: 'http://127.0.0.1:5000/log/brandInteraction',
    // INSPIRATION_TRACK_URL: 'http://127.0.0.1:5000/log/inspirationInteraction',
    // DETAIL_VIEW_URL: 'http://127.0.0.1:5000/data/getDetail',
    // SELECTION_VIEW_URL: 'http://127.0.0.1:5000/data/getSelection',
    // BRAND_SUBMISSION_URL: 'http://127.0.0.1:5000/log/brandSubmission',
    // KEYWORD_SEARCH_URL: 'http://127.0.0.1:5000/log/keywordInteraction'

    // // dev server dev config
    // INSPIRATION_URL: 'https://dev-search.mindspell.be/data/getInspiration',
    // INSPIRATION_SEARCH_URL: 'https://dev-search.mindspell.be/data/getResults',
    // TEXT_SEARCH_URL: 'https://dev-search.mindspell.be/data/getKeywordResults',
    // BRAND_EVENT_TRACK_URL: 'https://dev-search.mindspell.be/log/brandInteraction',
    // INSPIRATION_TRACK_URL: 'https://dev-search.mindspell.be/log/inspirationInteraction',
    // DETAIL_VIEW_URL: 'https://dev-search.mindspell.be/data/getDetail',
    // SELECTION_VIEW_URL: 'https://dev-search.mindspell.be/data/getSelection',
    // BRAND_SUBMISSION_URL: 'https://dev-search.mindspell.be/log/brandSubmission',
    // KEYWORD_SEARCH_URL: 'https://dev-search.mindspell.be/log/keywordInteraction'

    // prod server config
    INSPIRATION_URL: 'https://mindspeller.ai/data/getInspiration',
    INSPIRATION_SEARCH_URL: 'https://mindspeller.ai/data/getResults',
    TEXT_SEARCH_URL: 'https://mindspeller.ai/data/getKeywordResults',
    BRAND_EVENT_TRACK_URL: 'https://mindspeller.ai/log/brandInteraction',
    INSPIRATION_TRACK_URL: 'https://mindspeller.ai/log/inspirationInteraction',
    DETAIL_VIEW_URL: 'https://mindspeller.ai/data/getDetail',
    SELECTION_VIEW_URL: 'https://mindspeller.ai/data/getSelection',
    BRAND_SUBMISSION_URL: 'https://mindspeller.ai/log/brandSubmission',
    KEYWORD_SEARCH_URL: 'https://mindspeller.ai/log/keywordInteraction'
});
