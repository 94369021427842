<template>
  <nav
      class="navbar navbar-expand-lg navbar-light bg-light navbar-expand c-Header c-StickyRowTop">
    <div class="container-fluid">
      <div class="row flex-row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6">
          <a class="navbar-brand c-CursorPointer"
             v-on:click="returnToTitle"><img class="c-MdspHeaderLogo"
                                             src="../../assets/images/mdsp_logo.svg"/></a>
        </div>
        <div
            class="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 d-flex align-items-end">
          <span class="c-MdspHeaderLogoText">Discover ethical brands</span>
        </div>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 dropstart">
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" id="navbarDropdown" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
              <span class="navbar-toggler-icon" data-v-1aa4b66d=""></span>
            </a>
            <ul class="dropdown-menu c-Padding-20"
                aria-labelledby="navbarDropdown">
              <li>
                <router-link
                    class="dropdown-item c-ColorBlack c-No-Underline c-FontSize-14 c-LineHeight-40"
                    to="/inspiration">
                  <img class="c-BootstrapDropdownListItemSVG"
                       src="../../assets/heart_icon_blue_header.svg">
                  MOOD SEARCH
                </router-link>
              </li>
              <li>
                <router-link
                    class="dropdown-item c-ColorBlack c-No-Underline c-FontSize-14 c-LineHeight-40"
                    to="/selection-view">
                  <img
                      class="c-BootstrapDropdownListItemSVG"
                      src="../../assets/star_icon_navbar.svg">
                  FAVOURITES
                </router-link>
              </li>
              <li>
                <router-link
                    class="dropdown-item c-ColorBlack c-No-Underline c-FontSize-14 c-LineHeight-40"
                    to="/deletion-view">
                  <img
                      class="c-BootstrapDropdownListItemSVG"
                      src="../../assets/trash_icon_header.svg">
                  REMOVED
                </router-link>
              </li>
              <li>
                <router-link
                    class="dropdown-item c-ColorBlack c-No-Underline c-FontSize-14 c-LineHeight-40"
                    to="/recommend-brand">
                  <img
                      class="c-BootstrapDropdownListItemSVG"
                      src="../../assets/upload_icon_header.svg">
                  RECOMMEND BRAND
                </router-link>
              </li>
              <li>
                <router-link
                    class="dropdown-item c-ColorBlack c-No-Underline c-FontSize-14 c-LineHeight-40"
                    to="/about-us">
                  <span class="c-BootstrapDropdownListItemSVG c-MarginLeft-14"
                        src=""></span>
                  ABOUT US
                </router-link>
              </li>
              <li>
                <a
                    class="dropdown-item c-ColorBlack c-No-Underline c-FontSize-14 c-LineHeight-40"
                    href="https://www.mindspeller.com" target="_blank">
                  <span class="c-BootstrapDropdownListItemSVG c-MarginLeft-14"
                        src=""></span>
                  ADVERTISERS
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import router from "../../router";

export default {
  name: "Header.vue",

  methods: {
    returnToTitle: function () {
      router.push('/');
    },
  },

  data() {
    return {
      isHidden: true
    }
  },
}


</script>

<style lang="scss" scoped>
@import "src/components/style";
</style>