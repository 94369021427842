<template>
  <div class="row c-Footer align-items-center justify-content-center">
    <div class="col-auto d-flex">
      <div class=" c-AboutUsText c-MarginRight-10" align="center">
        <router-link
            class="c-No-Underline c-ColorWhite c-FontSize-12 c-CursorPointer"
            to="/about-us">About Us
        </router-link>
      </div>
      <div class=" c-AboutUsText c-MarginLeft-10" align="center">
        <a class="c-No-Underline c-ColorWhite c-FontSize-12 c-CursorPointer"
           href="https://www.mindspeller.com" target="_blank">Advertisers</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer.vue"
}
</script>

<style lang="scss" scoped>
@import "src/components/style";
</style>