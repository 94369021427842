<template>
  <!-- HEADER !-->
  <header-item></header-item>
  <div class="container-fluid">
      <!-- TEXT BELOW HEADER !-->
      <div class="row">
        <div class="col-6-justify-content-md-center mt-4">
          <h3 class="c-FontWeightBold" align="center">
            Select a picture<br> reflecting your mood
          </h3>
        </div>
      </div>

      <!-- TEXT BELOW HEADER !-->
      <div class="row mt-3 mb-3">
      </div>


      <!-- INSPIRATION SELECTION !-->
      <div class="row">
        <div class="col-12">
          <div v-masonry item-selector=".inspiration" fit-width="true" id='inspirations' v-on:scroll.passive="onScroll"
               class="">
            <!-- ITERATING OVER INSPIRATIONS !-->
            <div v-masonry-tile v-for="inspiration in items" :key="inspiration.id"
                 class="inspiration c-Inspiration c-No-Break">
              <img class="c-Height-100 c-DisplayBlock c-RoundCornersStrong c-ResultImage c-CursorPointer"
                   v-bind:src="getFileURL(inspiration.image)"
                   v-on:click="initInspirationSearch(inspiration.id)"
              />
            </div>
          </div>
        </div>
      </div>
    <!-- FOOTER !-->
  <footer-item></footer-item>
  </div>
</template>

<script>
import router, {Configuration} from "@/router";
import Header from './shared/Header.vue'
import Footer from './shared/Footer.vue'
import Cookies from "./shared/Cookies";

export default {
  name: "InspirationSelector",

  components: {
    'header-item': Header,
    'footer-item': Footer
  },

  data: function () {
    return {
      activeBtn: '',
    }
  },

  computed: {
    items() {
      return this.$store.state.items;
    },
    isDisabled() {
      return this.$store.state.liked.size === 0;
    },
  },
  created() {
    this.$store.dispatch('fetchInspirations');
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('scroll', this.onScroll);
    });
    // CLEARING LIKES UPON GOING BACK TO INSPIRATION SCREEN, AS WE WILL ONLY
    // HAVE ONE INSPIRATION FROM NOW ON
    this.$store.dispatch('clearLikesDislikes');
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    // Adding inspiration to 'liked' set
    clickedLike: function (id) {
      this.$store.dispatch('likedInspiration', id);
    },
    // Check whether given id is in 'liked' set
    isLiked: function (id) {
      return this.$store.state.liked.has(id);
    },
    // Check if user has reached the end of the page
    onScroll: function (e) {
      if (e.target.documentElement.scrollTop + e.target.documentElement.clientHeight >= e.target.documentElement.scrollHeight) {
        this.reachedScrollEnd()
      }
    },
    // Load more inspirations on viewport reaching the end of the page
    reachedScrollEnd: function () {
      if (this.items.length < Configuration.NUMBER_OF_INSPIRATION_PULLDOWNS * Configuration.NUMBER_OF_INSPIRATIONS_PER_PULLDOWN) {
        this.$store.dispatch('fetchInspirations');
      }
    },
    // Previous description is obsolete, currently it triggers search on only inspiration
    initInspirationSearch: function (id) {
      this.clickedLike(id);
      this.$store.state.results.length = 0;
      let banned_brands = Cookies.methods.getBannedBrands();
      this.$store.dispatch('inspirationSearch', banned_brands).then(
          this.$store.dispatch('inspirationTrack'),
          router.push('/results')
      );
    },
    clearLikes: function() {
      this.$store.dispatch('clearLikesDislikes');
    },
    // Function used to create S3 link for inspiration and result URL's
    getFileURL: function (identifier) {
      let s3_bucket = process.env.VUE_APP_S3_BUCKET;
      let aws_region = process.env.VUE_APP_AWS_REGION;
      let image_prefix = process.env.VUE_APP_IMAGE_PREFIX;
      return "https://" + s3_bucket + ".s3." + aws_region + ".amazonaws.com/" + image_prefix + "_" + identifier;
    },
  }
}
</script>
<style lang="scss" scoped>
@import "style";
</style>