<template>
  <!-- HEADER !-->
  <header-item></header-item>
  <div class="container-fluid">
    <!-- TEXT BELOW HEADER !-->
    <div class="row" align="center">
      <div class="col-6-justify-content-md-center">
        <h3 class="mt-4 c-FontWeightBold  ">
          {{ brandData.word }}
        </h3>
      </div>
    </div>

    <!-- SEARCH AGAIN BUTTON !-->
    <div class="row justify-content-center">
      <div class="col-6 mt-3">
        <span class="">
          <a @click="$router.go(-1)"
             class="c-No-Underline c-FontSize-14 c-FontWeight-300 c-MarginLeft-15 c-CursorPointer">
            <img class="c-InlineIcon c-FontSize-14 c-MarginRight-15"
                 src="../assets/arrow-left-blue.svg"/>
<!--            SEARCH AGAIN-->
            BACK TO MOODS
          </a>
        </span>
      </div>
      <div class="col-6">
      </div>
    </div>

    <!-- RESULTS !-->
    <div class="row">
      <result-card class="c-Width-50"
         v-on:open-share-dialog="$refs.share_modal.openShareDialog($event)"
         :data="brandData"
        :detailView="true">
      </result-card>
    </div>
    <div align="center">
      <button class="c-MdspButtonFull p-2 mb-5 c-CursorPointer"
              v-on:click="searchAgain()">SEARCH AGAIN
      </button>
    </div>

    <!-- TEXT BELOW RESULTS !-->
    <div class="row">
      <div class="col-6-justify-content-md-center mb-5" align="center">
        <p>
          Didn't find meaningful brands?<br>
          Share your favorite brand with us and help<br>
          develop the world's first human-centric AI.
        </p>
        <router-link class="c-No-Underline c-FontWeight-300 c-FontSize-14"
                     to="/recommend-brand">
          ADD BRAND RECOMMENDATION
        </router-link>
      </div>
    </div>
    <!-- FOOTER !-->
    <footer-item></footer-item>

    <!-- BOOTSTRAP SOCIAL NETWORK SHARE MODAL !-->
    <share-modal ref="share_modal"></share-modal>
  </div>
</template>

<script>
import Header from './shared/Header.vue'
import Footer from './shared/Footer.vue'
import ShareModal from "./shared/ShareModal";
import ResultCard from "./shared/ResultCard";
import axios from "axios";
import {Configuration} from "@/router";

export default {
  name: "DetailView",

  components: {
    'header-item': Header,
    'footer-item': Footer,
    'share-modal': ShareModal,
    'result-card': ResultCard,
  },

  data: function () {
    return {
      brandUrl: 'https://www.google.com',
      brandData: {},
    }
  },

  computed: {
    urlParam() {
      return this.$route.query.brand;
    },
    // brandData() {
    // },
    results() {
      return this.$store.state.results;
    },
  },
  created() {
    this.getBrandData(this.urlParam)
  },


  methods: {
    // Call an API to receive data of a particular brand (asset) by its id
    getBrandData: function (brand_id) {
      axios.post(Configuration.DETAIL_VIEW_URL,
          {"brand_id": brand_id}, {
            crossDomain: true,
            headers: {}
          }).then(response => {
        console.log(response)
        this.brandData = response.data;
      });
    },
    // Search again by going back to inspiration screen
    searchAgain: function () {
      this.$router.push('/inspiration');
    },
    // Transports user back to the top of the page
    scrollToTop: function () {
      window.scrollTo(0, 0);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "style";
</style>