import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
// import {VueReCaptcha} from "vue-recaptcha-v3";
import Hotjar from 'vue-hotjar'
import 'masonry-layout/dist/masonry.pkgd'
import mitt from 'mitt'
import { VueMasonryPlugin } from "vue-masonry/src/masonry-vue3.plugin";
import { createGtm } from '@gtm-support/vue-gtm';
import { datadogRum } from '@datadog/browser-rum';

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

const emitter = mitt();

let app = createApp(App)
app.config.globalProperties.emitter = emitter;
app.use(store)
app.use(router)
app.use(Hotjar, {id: process.env.VUE_APP_HOTJAR_KEY})
app.use(VueMasonryPlugin)
app.use(
  createGtm({
    id: 'GTM-P3S4MD3', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x'
    // },
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    // debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  })
);

datadogRum.init({
    applicationId: '88997068-abc6-40d7-b7e3-4c54322788b3',
    clientToken: 'pub894026d756ea6732c58685382989522b',
    site: 'datadoghq.eu',
    service:'mindspeller.ai',
    
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();

// app.use(VueReCaptcha, {siteKey: process.env.VUE_APP_RECAPTCHA_SECRET_KEY})
app.mount('#app')
