<template>
  <!-- HEADER !-->
  <header-item></header-item>
  <div class="container-fluid">
    <!-- TEXT BELOW HEADER !-->
    <div class="row c-FullScreen" align="center">
      <div class="col-6-justify-content-md-center">
        <h3 class="mt-4 c-FontWeightBold  ">
          My deleted brands
        </h3>
      </div>

    <div class="row">
        <div class="col-12">
          <div v-masonry item-selector=".selection_result" id='results'>
            <div v-masonry-tile class="selection_result" v-for="result in brandData"
                 :key="result.id">
              <result-card
                  v-on:open-share-dialog="$refs.share_modal.openShareDialog($event)"
                  :data="result">
              </result-card>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- FOOTER !-->
    <footer-item></footer-item>

    <!-- BOOTSTRAP SOCIAL NETWORK SHARE MODAL !-->
    <share-modal ref="share_modal"></share-modal>
  </div>
</template>

<script>
import Header from './shared/Header.vue'
import Footer from './shared/Footer.vue'
import ShareModal from "./shared/ShareModal";
import ResultCard from "./shared/ResultCard";
import Cookies from "./shared/Cookies";
import axios from "axios";
import {Configuration} from "@/router";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

export default {
  name: "DeletionView",

  components: {
    'header-item': Header,
    'footer-item': Footer,
    'share-modal': ShareModal,
    'result-card': ResultCard,
  },

  data: function () {
    return {
      brandData: '',
    }
  },

  computed: {
  },
  created() {
    this.getSelectionData();
  },


  methods: {
    getSelectionData: function () {
      let delete_list = Cookies.methods.getCookie('MDSP_DELETED_BRANDS');
      let delete_ids = Cookies.methods.serializeCookies(delete_list);
      axios.post(Configuration.SELECTION_VIEW_URL,
          {"brand_ids": delete_ids}, {}).then(response => {
        this.brandData = response.data;
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "style";
</style>