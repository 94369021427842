<template>
  <div class="row justify-content-center pb-4">
    <div class="col-auto">
      <div class="c-SearchForm">
        <input v-model="keywordSearch"
               v-on:keyup.enter="initTextSearch"
               type="text" class=""
               id="keyword-search"
                placeholder="eg. tired" />
        <button class="c-MdspButtonBasic c-MdspMagnifierButton c-CursorPointer"
                :disabled="isDisabled" v-on:click="initTextSearch">
          <img class="c-InlineIcon" src="../assets/mdsp_magnifier.svg"/>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
import Cookies from "./shared/Cookies";
import axios from "axios";
import {Configuration} from "../router";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

export default {
  name: "KeywordSearch.vue",

  data: function () {
    return {
      keywordSearch: '',
    }
  },

  computed: {
    isDisabled() {
      return this.keywordSearch.length === 0;
    }
  },

  methods: {
    // Initialize text search, empty previous results before searching for new
    initTextSearch() {
      this.$emit('init-text-search');
      let banned_brands = Cookies.methods.getBannedBrands();
      this.$store.dispatch('clearResults');
      this.$store.dispatch('updateSearchString', this.keywordSearch);
      this.$store.dispatch('textSearch', banned_brands).then(
          axios.post(Configuration.KEYWORD_SEARCH_URL,
          {"keywords": this.keywordSearch}),
          router.push('/results'),
      );
    }
  },

  components: {
  },

};

</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import "style";
</style>