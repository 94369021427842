<template>
  <!-- HEADER !-->
  <header-item></header-item>
  <div class="container-fluid">
    <!-- TEXT BELOW HEADER !-->
    <div class="row" align="center">
      <div class="col-6-justify-content-md-center">
        <h3 class="mt-4 c-FontWeightBold  ">
          Explore and share <br>these original <br>brands
        </h3>
      </div>
    </div>

    <!-- SEARCH AGAIN BUTTON !-->
    <div class="row">
      <div class="col-8 mt-3">
        <span class="">
          <a @click="$router.go(-1)"
             class="c-No-Underline c-FontSize-14 c-FontWeight-300 c-CursorPointer">
            <img class="c-InlineIcon c-FontSize-14 c-MarginRight-15"
                 src="../assets/arrow-left-blue.svg"/>
            <!--                SEARCH AGAIN-->
              BACK TO MOODS
          </a>
        </span>
      </div>
      <div class="col-6">
      </div>
    </div>

    <!-- RESULTS !-->
    <div v-if="processing" class="row justify-content-center" align="center">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="c-MdspText">
        Sometimes the calculations can take more than 30 seconds... Thank you for your patience!
      </p>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-masonry item-selector=".result" id='results'>
          <div v-masonry-tile class="result" v-for="result in results"
               :key="result.id">
            <result-card
                v-on:open-share-dialog="$refs.share_modal.openShareDialog($event)"
                :data="result">
            </result-card>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center" v-if="!processing">
      <div class="col-auto">
        <button class="c-MdspButtonFull c-CursorPointer" v-on:click="loadMoreResults" :disabled="loadMoreDisabled">
          LOAD MORE<br>
          RESULTS
        </button>
      </div>
    </div>
    <div
        class="row d-flex justify-content-center mt-4 c-StickyRow c-ColorWhiteSemitransparent c-z-10">
      <div class="col-5 d-flex justify-content-end c-FloatingMenuButtonleft">
        <router-link to="/inspiration">
          <button class="c-MdspButtonFull">
            SEARCH AGAIN
          </button>
        </router-link>
      </div>
      <div class="col-5 d-flex justify-content-start c-FloatingMenuButtonRight">
        <router-link to="/recommend-brand">
          <button class="c-MdspButtonFull">
            RECOMMEND<br> NEW BRAND
          </button>
        </router-link>
      </div>
    </div>

    <!-- TEXT BELOW RESULTS !-->
    <div class="row" v-if="!processing">
      <div class="col-6-justify-content-md-center mb-2" align="center">
        <p>
          Didn't find any brands that reflect your mood?<br>
          Try describing your mood.
        </p>
      </div>
    </div>
    <keyword-search
        v-on:init-text-search="clearResultIndex"
        v-if="!processing">
    </keyword-search>


    <!-- FOOTER !-->
    <footer-item></footer-item>

    <!-- BOOTSTRAP SOCIAL NETWORK SHARE MODAL !-->
    <share-modal ref="share_modal"></share-modal>
  </div>
</template>

<script>
import Header from './shared/Header.vue'
import Footer from './shared/Footer.vue'
import ShareModal from "./shared/ShareModal";
import ResultCard from "./shared/ResultCard";
import KeywordSearch from "./KeywordSearch";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

export default {
  name: "ResultsViewer",

  components: {
    'header-item': Header,
    'footer-item': Footer,
    'share-modal': ShareModal,
    'keyword-search': KeywordSearch,
    'result-card': ResultCard,
  },

  data: function () {
    return {
      result_index: 0,
      // results: this.$store.state.results,
    }
  },

  computed: {
    results() {
      return this.$store.state.results.slice(0, (9 + (this.result_index * 9)));
    },
    processing() {
      return this.$store.state.resultsProcessing;
    },
    loadMoreDisabled() {
      return this.result_index >= 3;
    }
  },
  created() {
  },

  mounted() {
  },

  methods: {
    loadMoreResults: function() {
      this.result_index = this.result_index + 1;
    },
    clearResultIndex: function() {
      this.result_index = 0;
    },
    // Search again by going back to inspiration screen
    searchAgain: function () {
      this.$router.push('/inspiration');
    },
    // Transports user back to the top of the page
    scrollToTop: function () {
      window.scrollTo(0, 0);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "style";
</style>