<template>
  <div class="modal fade" id="shareModal" tabindex="-1" role="dialog"
       aria-labelledby="shareModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close c-MdspBlue"
                  v-on:click="closeModal()"
                  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row d-flex c-FlexWrap mb-33">
            <div
                class="col-4 d-flex justify-content-center align-items-center">
              <img class="c-Icon c-CursorPointer"
                   src="../../assets/facebook_icon.svg"
                   v-on:click="open_facebook_share"/>
              <!--                <iframe class="c-Width-100" id='facebook-share' src=""-->
              <!--                        width="77" height="28"-->
              <!--                        style="border:none;overflow:hidden" scrolling="no"-->
              <!--

                     frameborder="0" allowfullscreen="true"-->
              <!--                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">-->
              <!--                </iframe>-->
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
              <img class="c-Icon c-CursorPointer"
                   v-on:click="shareResult('whatsapp')"
                   src="../../assets/whatsapp_icon.svg"/>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
              <img class="c-Icon c-CursorPointer"
                   v-on:click="shareResult('email')"
                   src="../../assets/mail_icon.svg"/>
            </div>
          </div>
          <div class="row justify-content-center mb-33">
            <div class="col-auto">
              <div class="c-SearchForm d-flex">
                <input v-model="brandUrl" type="text"
                       id="copy-url"/>
                <button
                    class="c-MdspCopyButton c-CursorPointer d-flex align-items-center justify-content-center"
                    v-on:click="shareResult('copy')">
                  <img class="c-CopyIcon"
                       src="../../assets/copy_icon.svg"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Configuration} from "@/router";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

export default {
  name: "ShareModal.vue",

  data: function () {
    return {
      brandUrl: 'https://www.google.com',
      facebookUrl: '',
      result_url: new String(),
      result_id: new Number(),
    }
  },

  computed: {
    results() {
      return this.$store.state.results;
    },
    processing() {
      return this.$store.state.resultsProcessing;
    }

  },
  created() {
  },


  methods: {
    // Get detail view URL, which is used to access detail-view page from
    // the internet
    getDetailViewURL: function (result) {
      console.log(result)
      let url = 'https://www.' + process.env.VUE_APP_MDSP_SEARCH_DOMAIN + '/#/detail-view?brand=' + result;
      return url
    },

    // Open the modal containing various share methods
    openShareDialog: function (result) {
      axios.post(Configuration.BRAND_EVENT_TRACK_URL,
          {
            "brand_id": result.id,
            "action": "share"
          });
      // Populate the modal with appropriate data
      this.populateShareResult(result);
      this.openModal();
    },

    openModal: function () {
      $('#shareModal').modal('show');
    },

    closeModal: function () {
      $('#shareModal').modal('hide');
    },

    // Re-usable sharing method
    shareResult: function (medium) {
      let instruction = 'Copy paste this url in your browser to discover your brand recommendation.'
      let message = 'Check out this brand I discovered with mindspeller.ai!';
      let content = message + '%0D%0A' + instruction;
      // If user chooses either email, whatsapp or copying to clipboard
      switch (medium) {
          // In case of email, use standard mailto: method
        case 'email':
          window.open('mailto:?body=' + content + '%0D%0A' + this.result_url, '_blank');
          break;
          // In case of whatsapp, use deeplink to share
        case 'whatsapp':
          window.open('https://wa.me/?text=' + content + '\n' + this.result_url, '_blank');
          break;
        case 'messenger':
          break;
        case 'copy':
          document.getElementById('copy-url').select();
          document.execCommand("copy");
          break;
      }
    },

    // Populating share modal with appropriate data
    populateShareResult: function (result) {
      this.result_url = this.getDetailViewURL(result.id);
      this.result_id = result.id
      this.facebookUrl = this.create_facebook_link(result.id);
      // document.getElementById('facebook-share').setAttribute('src', this.create_facebook_link(result.id))
      this.brandUrl = this.getDetailViewURL(result.id);
    },

    // Create link to share on Facebook URL
    create_facebook_link: function (id) {
      // return "https://www.facebook.com/plugins/share_button.php?href=" + process.env.VUE_APP_MDSP_SEARCH_DOMAIN + "/brand/" + id + "%2F&layout=button&size=large&width=77&height=28&appId"
      return "https://www.facebook.com/sharer/sharer.php?u=" + process.env.VUE_APP_MDSP_SEARCH_DOMAIN + "/#/brand/" + id;
    },
    // Open new window with share to Facebook URL
    open_facebook_share: function () {
      window.open(this.facebookUrl);
    }

  },
}
</script>

<style lang="scss" scoped>
@import "src/components/style";
</style>